import { CONNECT_DIALOG_HIDE, CONNECT_DIALOG_SHOW, NAVBAR_DRAWER_VALUE_SET } from '../constants/navBar';

export const setNavBarDrawer = (value) => {
    return {
        type: NAVBAR_DRAWER_VALUE_SET,
        value,
    };
};

export const showConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_SHOW,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_HIDE,
    };
};
