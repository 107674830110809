import { config } from '../config';

const URL = config.API_URL;

export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;
export const ACCESS_TOKEN_URL = `${URL}/user/auth/refresh-token`;
export const PROFILE_DETAILS_URL = `${URL}/me/profile/details`;
export const LIVE_STREAMS_URL = `${URL}/channels/${config.CHANNEL_ID}/livestreams`;
export const PROFILE_NICK_NAME_UPDATE_URL = `${URL}/me/sendbird/nickname`;

export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const urlFetchPollsList = (livestreamId) =>
    `${URL}/channels/${config.CHANNEL_ID}/livestreams/${livestreamId}/polls?all=true`;
export const urlFetchPollResponse = (livestreamId, id) =>
    `${URL}/channels/${config.CHANNEL_ID}/livestreams/${livestreamId}/polls/${id}/my/response`;
export const urlFetchPollStats = (livestreamId, id) =>
    `${URL}/channels/${config.CHANNEL_ID}/livestreams/${livestreamId}/polls/${id}/public-stats`;
export const urlUpdatePollResponse = (livestreamId, id) =>
    `${URL}/channels/${config.CHANNEL_ID}/livestreams/${livestreamId}/polls/${id}/responses`;
