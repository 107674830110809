import { combineReducers } from 'redux';
import { CONNECT_DIALOG_HIDE, CONNECT_DIALOG_SHOW, NAVBAR_DRAWER_VALUE_SET } from '../constants/navBar';

const navBarDrawer = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case NAVBAR_DRAWER_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_DIALOG_SHOW:
        return true;
    case CONNECT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    navBarDrawer,
    connectDialog,
});
