import { combineReducers } from 'redux';
import {
    POLL_RESPONSE_FETCH_ERROR,
    POLL_RESPONSE_FETCH_IN_PROGRESS,
    POLL_RESPONSE_FETCH_SUCCESS,
    POLL_RESPONSE_UPDATE_ERROR,
    POLL_RESPONSE_UPDATE_IN_PROGRESS,
    POLL_RESPONSE_UPDATE_SUCCESS,
    POLL_STATS_FETCH_ERROR,
    POLL_STATS_FETCH_IN_PROGRESS,
    POLL_STATS_FETCH_SUCCESS,
    POLLS_LIST_FETCH_ERROR,
    POLLS_LIST_FETCH_IN_PROGRESS,
    POLLS_LIST_FETCH_SUCCESS,
} from '../constants/pollsList';
import { DISCONNECT_SET } from '../constants/wallet';

const pollsList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case POLLS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case POLLS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
        // case POLL_RESPONSE_FETCH_SUCCESS: {
        //     const polls = state.value;
        //     if (polls && polls.length) {
        //         polls.map((val, index) => {
        //             if (val._id === action.id) {
        //                 const obj = val;
        //                 obj.participated_details = action.value;
        //                 polls.splice(index, 1, obj);
        //             }
        //         });
        //     }
        //     return {
        //         inProgress: false,
        //         value: action.value,
        //     };
        // }
    case POLLS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const pollResponse = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case POLL_RESPONSE_UPDATE_IN_PROGRESS:
    case POLL_RESPONSE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case POLL_RESPONSE_UPDATE_SUCCESS:
    case POLL_RESPONSE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: {
                ...state.value,
                [action.id]: action.value,
            },
        };
    case POLL_RESPONSE_UPDATE_ERROR:
    case POLL_RESPONSE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: {},
        };
    default:
        return state;
    }
};

const pollStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case POLL_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case POLL_STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: {
                ...state.value,
                [action.id]: action.value,
            },
        };
    case POLL_STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    pollsList,
    pollResponse,
    pollStats,
});
