import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { ACCESS_TOKEN_FETCH_ERROR, PROFILE_DETAILS_FETCH_ERROR, UPDATE_PROFILE_NICK_NAME_ERROR, UPDATE_PROFILE_NICK_NAME_SUCCESS, VERIFY_ACCOUNT_ERROR } from '../constants/account';
import { POLL_RESPONSE_FETCH_ERROR, POLL_RESPONSE_UPDATE_ERROR } from '../constants/pollsList';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case MESSAGE_SHOW:
    case PROFILE_DETAILS_FETCH_ERROR:
    case VERIFY_ACCOUNT_ERROR:
    case ACCESS_TOKEN_FETCH_ERROR:
    case POLL_RESPONSE_FETCH_ERROR:
    case POLL_RESPONSE_UPDATE_ERROR:
    case UPDATE_PROFILE_NICK_NAME_SUCCESS:
    case UPDATE_PROFILE_NICK_NAME_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
