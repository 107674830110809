import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { Button, Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import variables from '../../utils/variables';
import { ReactComponent as PoweredBy } from '../../assets/networks/omniflix.svg';
import { ReactComponent as SponsoredBy } from '../../assets/networks/osmosis.svg';
import OsmosisIcon from '../../assets/networks/osmosis.png';
import WithRouter from '../../components/WithRouter';
import KeplrConnect from './KeplrConnect';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { setNavBarDrawer } from '../../actions/navBar';
import CloseIcon from '@mui/icons-material/Close';
import ConnectDialog from './ConnectDialog';

const NavBar = (props) => {
    const handleSideBar = () => {
        props.setNavBarDrawer(!props.open);
    };

    const handleClose = () => {
        props.setNavBarDrawer(!props.open);
    };

    return (
        <div className={props.open ? 'active_drawer navbar' : 'navbar'}>
            <div className="section1">
                <Button className="nav_logo">
                    <Logo/>
                </Button>
                <div className="live_section">
                    <Button className="live_button">
                        <span className="dot"/>
                        <p>{variables[props.lang].live}</p>
                    </Button>
                </div>
            </div>
            <div className="section2">
                <div>
                    <p>{variables[props.lang].powered_by}</p>
                    <PoweredBy/>
                </div>
                <p className="divider"/>
                <div>
                    <p>{variables[props.lang].sponsored_by}</p>
                    <SponsoredBy/>
                </div>
            </div>
            <KeplrConnect/>
            <div className="mobile_view">
                <Button onClick={handleSideBar}>
                    <DehazeIcon/>
                </Button>
                <Drawer
                    anchor="top"
                    className="navbar_drawer navbar"
                    open={props.open}
                    onClose={handleClose}>
                    <div>
                        <Button
                            className="close_button"
                            onClick={handleClose}>
                            <CloseIcon/>
                        </Button>
                        <div className="section1">
                            <Button className="nav_logo">
                                <Logo/>
                            </Button>
                            <div className="live_section">
                                <Button className="live_button">
                                    <span className="dot"/>
                                    <p>{variables[props.lang].live}</p>
                                </Button>
                            </div>
                        </div>
                        <div className="section2">
                            <div>
                                <p>{variables[props.lang].powered_by}</p>
                                <PoweredBy/>
                            </div>
                            <p className="divider"/>
                            <div>
                                <p>{variables[props.lang].sponsored_by}</p>
                                <img alt="sponsored" src={OsmosisIcon}/>
                            </div>
                        </div>
                        <KeplrConnect/>
                    </div>
                </Drawer>
            </div>
            <ConnectDialog/>
        </div>
    );
};

NavBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setNavBarDrawer: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.navBar.navBarDrawer.value,
    };
};

const actionToProps = {
    setNavBarDrawer,
};

export default WithRouter(connect(stateToProps, actionToProps)(NavBar));
