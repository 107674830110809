export const CONNECT_BC_ACCOUNT_IN_PROGRESS = 'CONNECT_BC_ACCOUNT_IN_PROGRESS';
export const CONNECT_BC_ACCOUNT_SUCCESS = 'CONNECT_BC_ACCOUNT_SUCCESS';
export const CONNECT_BC_ACCOUNT_ERROR = 'CONNECT_BC_ACCOUNT_ERROR';

export const VERIFY_ACCOUNT_IN_PROGRESS = 'VERIFY_ACCOUNT_IN_PROGRESS';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';

export const ACCESS_TOKEN_FETCH_IN_PROGRESS = 'ACCESS_TOKEN_FETCH_IN_PROGRESS';
export const ACCESS_TOKEN_FETCH_SUCCESS = 'ACCESS_TOKEN_FETCH_SUCCESS';
export const ACCESS_TOKEN_FETCH_ERROR = 'ACCESS_TOKEN_FETCH_ERROR';

export const PROFILE_DETAILS_FETCH_IN_PROGRESS = 'PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_ERROR = 'PROFILE_DETAILS_FETCH_ERROR';

export const UPDATE_NAME_SET = 'UPDATE_NAME_SET';
export const UPDATE_NAME_VALUE_SET = 'UPDATE_NAME_VALUE_SET';

export const UPDATE_PROFILE_NICK_NAME_SUCCESS = 'UPDATE_PROFILE_NICK_NAME_SUCCESS';
export const UPDATE_PROFILE_NICK_NAME_IN_PROGRESS = 'UPDATE_PROFILE_NICK_NAME_IN_PROGRESS';
export const UPDATE_PROFILE_NICK_NAME_ERROR = 'UPDATE_PROFILE_NICK_NAME_ERROR';
