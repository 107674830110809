import { combineReducers } from 'redux';
import {
    LIVE_STREAMS_FETCH_ERROR,
    LIVE_STREAMS_FETCH_IN_PROGRESS,
    LIVE_STREAMS_FETCH_SUCCESS,
    LIVE_USER_COUNT_SET,
} from '../constants/videoPlayer';

const liveStreams = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case LIVE_STREAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case LIVE_STREAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const userCount = (state = {}, action) => {
    switch (action.type) {
    case LIVE_USER_COUNT_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    liveStreams,
    userCount,
});
