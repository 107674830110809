import { combineReducers } from 'redux';
import { MESSAGE_VALUE_SET, TAB_VALUE_SET } from '../constants/home';

const messageValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MESSAGE_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const tabValue = (state = {
    value: 'chat',
}, action) => {
    switch (action.type) {
    case TAB_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    messageValue,
    tabValue,
});
