import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import account from './account';
import home from './home';
import videoPlayer from './videoPlayer';
import pollsList from './pollsList';
import navBar from './navBar';
import socket from './socket';

export default combineReducers({
    account,
    language,
    snackbar,
    home,
    videoPlayer,
    pollsList,
    navBar,
    socket,
});
