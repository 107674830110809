export const POLLS_LIST_FETCH_IN_PROGRESS = 'POLLS_LIST_FETCH_IN_PROGRESS';
export const POLLS_LIST_FETCH_SUCCESS = 'POLLS_LIST_FETCH_SUCCESS';
export const POLLS_LIST_FETCH_ERROR = 'POLLS_LIST_FETCH_ERROR';

export const POLL_RESPONSE_FETCH_IN_PROGRESS = 'POLL_RESPONSE_FETCH_IN_PROGRESS';
export const POLL_RESPONSE_FETCH_SUCCESS = 'POLL_RESPONSE_FETCH_SUCCESS';
export const POLL_RESPONSE_FETCH_ERROR = 'POLL_RESPONSE_FETCH_ERROR';

export const POLL_STATS_FETCH_IN_PROGRESS = 'POLL_STATS_FETCH_IN_PROGRESS';
export const POLL_STATS_FETCH_SUCCESS = 'POLL_STATS_FETCH_SUCCESS';
export const POLL_STATS_FETCH_ERROR = 'POLL_STATS_FETCH_ERROR';

export const POLL_RESPONSE_UPDATE_IN_PROGRESS = 'POLL_RESPONSE_UPDATE_IN_PROGRESS';
export const POLL_RESPONSE_UPDATE_SUCCESS = 'POLL_RESPONSE_UPDATE_SUCCESS';
export const POLL_RESPONSE_UPDATE_ERROR = 'POLL_RESPONSE_UPDATE_ERROR';
