import React from 'react';
import './index.css';
import { ReactComponent as Logo } from '../../assets/wide_logo.svg';

const Footer = (props) => {
    return (
        <div className="footer">
            <div>
                <Logo/>
            </div>
            <div>
                © Copyright 2024 - OmniFlix
            </div>
        </div>
    );
};

export default Footer;
