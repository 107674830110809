import React, { useState } from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { aminoSignTx, initializeChain, setAccountAddress } from '../../../actions/account/wallet';
import variables from '../../../utils/variables';
import { fetchBalance } from '../../../actions/account/BCDetails';
import { hideConnectDialog } from '../../../actions/navBar';
import keplrIcon from '../../../assets/wallets/keplr.png';
import { config } from '../../../config';
import { connectBCAccount, fetchProfileDetails, verifyAccount } from '../../../actions/account';

const KeplrButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeKeplr = () => {
        setInProgress(true);
        props.initializeChain((address) => {
            if (address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.keplr) {
                            window.keplr.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        props.aminoSignTx(tx, address[0].address, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                props.verifyAccount(res._id, data, (error) => {
                                    if (!error) {
                                        localStorage.setItem('ccc_live_stream_address', address[0].address);
                                        props.fetchBalance(address[0].address);
                                        props.fetchProfileDetails();
                                        props.setAccountAddress(address[0].address);
                                        props.hideConnectDialog();
                                        if (window.keplr) {
                                            window.keplr.defaultOptions = {};
                                        }
                                    }
                                    setInProgress(false);
                                });
                            } else {
                                setInProgress(false);
                            }
                        });
                    } else {
                        setInProgress(false);
                    }
                });
            } else {
                setInProgress(false);
            }
        });
    };

    const connectInProgress = inProgress;
    return (
        <Button
            className="keplr_button"
            disabled={connectInProgress}
            variant="contained"
            onClick={initializeKeplr}>
            <img alt="logo" src={keplrIcon}/>
            {connectInProgress ? variables[props.lang].connecting + '...' : variables[props.lang]['keplr_wallet']}
        </Button>
    );
};

KeplrButton.propTypes = {
    aminoSignTx: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    connectBCAccount: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    verifyAccount: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        inProgress: state.account.wallet.connection.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        lang: state.language,
    };
};

const actionToProps = {
    aminoSignTx,
    initializeChain,
    connectBCAccount,
    fetchBalance,
    fetchProfileDetails,
    hideConnectDialog,
    setAccountAddress,
    verifyAccount,
};

export default connect(stateToProps, actionToProps)(KeplrButton);
