import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../components/TextField';
import { Button, InputAdornment } from '@mui/material';
import { setUpdateName, setUpdateNameValue, updateProfile, fetchProfileDetails } from '../../actions/account';
import Icon from '../../components/Icon';
import { showMessage } from '../../actions/snackbar';

const UpdateNameTextField = (props) => {
    const handleClick = () => {
        const profileName = props.profileDetails && props.profileDetails.sendbird && props.profileDetails.sendbird.nickname;
        const data = {
            nickname: props.value,
        };

        if (props.value !== '' && (props.value !== profileName)) {
            props.updateProfile(data, (cb) => {
                props.fetchProfileDetails();
                props.setUpdateName(false);
            });
        } else {
            props.setUpdateName(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const profileName = props.profileDetails && props.profileDetails.sendbird && props.profileDetails.sendbird.nickname;
            const data = {
                nickname: props.value,
            };

            if (props.value !== '' && (props.value !== profileName)) {
                props.updateProfile(data, (cb) => {
                    props.fetchProfileDetails();
                    props.setUpdateName(false);
                });
            } else {
                props.setUpdateName(false);
            }
        }
    };

    const handleChange = (value) => {
        if (value && value.length && value.length > 15) {
            props.showMessage('Maximum 15 characters are allowed for the nickname.');
            return;
        }

        props.onChange(value);
    };

    return (
        <TextField
            id="update_name"
            inputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                            className="action_icon"
                            onClick={handleClick}>
                            <Icon className="checkmark" icon="checkmark"/>
                        </Button>
                    </InputAdornment>
                ),
            }}
            name="update_name"
            value={props.value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}/>
    );
};

UpdateNameTextField.propTypes = {
    fetchProfileDetails: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    setUpdateName: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.account.updateNameValue,
        profileDetails: state.account.profileDetails.value,
    };
};

const actionsToProps = {
    onChange: setUpdateNameValue,
    setUpdateName,
    updateProfile,
    showMessage,
    fetchProfileDetails,
};

export default connect(stateToProps, actionsToProps)(UpdateNameTextField);
