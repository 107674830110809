import { config } from '../config';

const variables = {
    en: {
        // navbar
        live: 'Live',
        powered_by: 'Powered by',
        sponsored_by: 'Sponsored by',
        connect: 'Connect',
        connect_with_omniflix: 'Connect OmniFlix Account',
        disconnect: 'disconnect',
        connecting: 'Connecting',
        add_nick_name: 'Add Nick Name',

        live_chat: 'Live chat',
        send_your_message: 'Send your message here',
        chat: 'Chat',
        poll: 'Poll',
        polls: 'Polls',
        interactions: 'Interactions',
        awards: 'Awards',
        forked_awards: 'Forked Awards',

        stream_live_in: 'Live stream will go live in',
        add_reminder: 'Add reminder',
        watch_replay: ' watch Replay',
        stream_for_day_ended: 'Stream for the day has ended. Come back tomorrow for more!',
        connection_info: 'Welcome to Cosmoverse Live! We promote a positive, civilized stream, and strictly prohibit any content that is controversial, illegal, or vulgar.',

        connect_wallet: 'Connect Your Wallet',
        keplr_wallet: 'Keplr Wallet',
        wallet_connect: 'Wallet Connect',
        chain_not_supported: `${config.CHAIN_ID} are not currently supported chain`,
        leap: 'Leap',
    },
};

export default variables;
