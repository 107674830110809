import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    UPDATE_NAME_SET,
    UPDATE_NAME_VALUE_SET,
    UPDATE_PROFILE_NICK_NAME_ERROR,
    UPDATE_PROFILE_NICK_NAME_IN_PROGRESS,
    UPDATE_PROFILE_NICK_NAME_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/account';
import Axios from 'axios';
import { ACCESS_TOKEN_URL, CONNECT_ACCOUNT_URL, PROFILE_DETAILS_URL, PROFILE_NICK_NAME_UPDATE_URL, urlVerifyAccount } from '../../constants/url';

const connectBCAccountInProgress = () => {
    return {
        type: CONNECT_BC_ACCOUNT_IN_PROGRESS,
    };
};

const connectBCAccountSuccess = (value) => {
    return {
        type: CONNECT_BC_ACCOUNT_SUCCESS,
        value,
    };
};

const connectBCAccountError = (message) => {
    return {
        type: CONNECT_BC_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const connectBCAccount = (data, cb) => (dispatch) => {
    dispatch(connectBCAccountInProgress());

    Axios.post(CONNECT_ACCOUNT_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(connectBCAccountSuccess(res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            dispatch(connectBCAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const verifyAccountInProgress = () => {
    return {
        type: VERIFY_ACCOUNT_IN_PROGRESS,
    };
};

const verifyAccountSuccess = (message, value) => {
    return {
        type: VERIFY_ACCOUNT_SUCCESS,
        message,
        value,
    };
};

const verifyAccountError = (message) => {
    return {
        type: VERIFY_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const verifyAccount = (userId, data, cb) => (dispatch) => {
    dispatch(verifyAccountInProgress());

    const url = urlVerifyAccount(userId);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(verifyAccountSuccess('Success', res.data && res.data.result['access_token']));
            localStorage.setItem('acToken_of_ccc_live_stream', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_of_ccc_live_stream', res.data && res.data.result['refresh_token']);
            cb(null);
        })
        .catch((error) => {
            dispatch(verifyAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.message
                    ? error.response.data.message.message
                    : error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            cb(error);
        });
};

const fetchAccessTokenInProgress = () => {
    return {
        type: ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchAccessTokenSuccess = (message, value) => {
    return {
        type: ACCESS_TOKEN_FETCH_SUCCESS,
        message,
        value,
    };
};

const fetchAccessTokenError = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_ERROR,
        message,
    };
};

export const fetchAccessToken = (cb) => (dispatch) => {
    dispatch(fetchAccessTokenInProgress());

    const data = {
        refreshToken: localStorage.getItem('rfToken_of_ccc_live_stream'),
    };

    Axios.post(ACCESS_TOKEN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            localStorage.setItem('acToken_of_ccc_live_stream', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_of_ccc_live_stream', res.data && res.data.result['refresh_token']);
            dispatch(fetchAccessTokenSuccess('Success', res.data && res.data.result['access_token']));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAccessTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchProfileDetailsInProgress = () => {
    return {
        type: PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: PROFILE_DETAILS_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProfileDetails = () => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_ccc_live_stream'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const setUpdateName = (value) => {
    return {
        type: UPDATE_NAME_SET,
        value,
    };
};

export const setUpdateNameValue = (value) => {
    return {
        type: UPDATE_NAME_VALUE_SET,
        value,
    };
};

const updateProfileInProgress = () => {
    return {
        type: UPDATE_PROFILE_NICK_NAME_IN_PROGRESS,
    };
};

const updateProfileSuccess = (value, message) => {
    return {
        type: UPDATE_PROFILE_NICK_NAME_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateProfileError = (message) => {
    return {
        type: UPDATE_PROFILE_NICK_NAME_ERROR,
        message,
        variant: 'error',
    };
};

export const updateProfile = (data, cb) => (dispatch) => {
    dispatch(updateProfileInProgress());

    Axios.put(PROFILE_NICK_NAME_UPDATE_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_ccc_live_stream'),
        },
    })
        .then((res) => {
            dispatch(updateProfileSuccess(res.data && res.data.message, res.data && res.data.message));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateProfileError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};
