import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    UPDATE_NAME_SET,
    UPDATE_NAME_VALUE_SET,
    UPDATE_PROFILE_NICK_NAME_ERROR,
    UPDATE_PROFILE_NICK_NAME_IN_PROGRESS,
    UPDATE_PROFILE_NICK_NAME_SUCCESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/account';
import { DISCONNECT_SET } from '../../constants/wallet';

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
    case UPDATE_PROFILE_NICK_NAME_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: { ...action.value },
        };
    case PROFILE_DETAILS_FETCH_ERROR:
    case UPDATE_PROFILE_NICK_NAME_ERROR:
    case UPDATE_PROFILE_NICK_NAME_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: localStorage.getItem('acToken_of_ccc_live_stream'),
}, action) => {
    switch (action.type) {
    case ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCESS_TOKEN_FETCH_SUCCESS:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const updateName = (state = false, action) => {
    switch (action.type) {
    case UPDATE_NAME_SET:
        return action.value;
    default:
        return state;
    }
};
const updateNameValue = (state = '', action) => {
    switch (action.type) {
    case UPDATE_NAME_VALUE_SET:
        return action.value;
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return action.value && action.value.sendbird && action.value.sendbird.nickname ? action.value.sendbird.nickname : '';
    default:
        return state;
    }
};

const nickname = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case UPDATE_PROFILE_NICK_NAME_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPDATE_PROFILE_NICK_NAME_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case UPDATE_PROFILE_NICK_NAME_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    profileDetails,
    token,
    wallet,
    bc: BCDetails,
    updateName,
    updateNameValue,
    nickname,
});
